import { ChevronDown, ChevronUp } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  ToolTipDefault,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

export function MenuItem({
  label,
  icon,
  href,
  subItems,
  isUniversity,
  totalPendingApprove,
  totalPendingApprovals,
  expanded,
  toggleMenu = () => {},
  chatSummary,
  expandSubItem,
  setExpandSubItem,
}: any) {
  const pathname = usePathname();
  const hrefCheckActive = new URL(href, window.location.origin).pathname;
  const isActive =
    hrefCheckActive === "/" || hrefCheckActive === "/hosting"
      ? pathname === hrefCheckActive
      : pathname?.includes(hrefCheckActive);

  const menuItemActiveClass = isUniversity
    ? "bg-gold-200 text-coffee-900 p3-bold-mobile md:p1-bold"
    : "bg-emerald-500 text-gold-50 p3-bold-mobile md:p1-bold";

  const menuItemHoverClass = "hover:bg-[#323937] hover:text-white-900";

  const hasSubItems = Boolean(subItems?.length);

  const subItemExpanded = hasSubItems && expandSubItem === href;

  const hasPendingBookings = label === "Bookings" && totalPendingApprove > 0;
  const hasPendingApprovals =
    label === "Approvals" && totalPendingApprovals > 0;

  const hasUnreadMessages =
    label === "Messages" && chatSummary?.unread_message_count > 0;

  const menuItemBadgeClass = cn(
    "absolute -top-1 right-0 h-[22px] rounded-full bg-gold-400 px-[7px] text-gold-50 hover:bg-gold-400",
    expanded && "right-2 top-[30%] h-[26px] rounded-[43px] px-4 md:top-2",
  );

  const Item = () => (
    <Button
      variant="ghost"
      size="sm"
      className={cn(
        "p3-mobile md:p4-medium relative h-[66px] w-full justify-start rounded-none px-4 text-coffee-200 md:h-[42px] md:rounded-sm md:px-2",
        isActive ? menuItemActiveClass : menuItemHoverClass,
        !expanded && "justify-center",
        subItemExpanded && "!bg-[#323937] !text-coffee-200",
      )}
      onClick={() => !hasSubItems && toggleMenu()}
      aria-label={
        hasPendingBookings ? `Bookings ${totalPendingApprove} pending` : label
      }
      tabIndex={-1}
    >
      <div className={cn(expanded && "mr-2")}>{icon}</div>
      {expanded && <span className="grow text-left">{label}</span>}
      {hasPendingBookings && (
        <Badge variant="secondary" className={menuItemBadgeClass}>
          {totalPendingApprove}
        </Badge>
      )}
      {hasUnreadMessages && (
        <Badge variant="secondary" className={menuItemBadgeClass}>
          {chatSummary?.unread_message_count}
        </Badge>
      )}
      {hasPendingApprovals && (
        <Badge variant="secondary" className={menuItemBadgeClass}>
          {totalPendingApprovals}
        </Badge>
      )}
      {hasSubItems && expanded && (
        <span>
          {subItemExpanded ? (
            <ChevronUp size={24} />
          ) : (
            <ChevronDown size={24} />
          )}
        </span>
      )}
    </Button>
  );

  const SubItem = ({ icon, label, href }: any) => {
    const url = new URL(href, window.location.origin);
    const subItemPathName = url.pathname;
    return (
      <ToolTipDefault
        content={label}
        side="right"
        align="start"
        sideOffset={10}
        className="px-3 py-1.5"
      >
        <Link href={href} className="block">
          <Button
            variant="ghost"
            size="sm"
            className={cn(
              "p3-bold-mobile md:p4-medium relative h-[66px] w-full justify-start text-coffee-200 md:h-[42px]",
              pathname?.includes(subItemPathName)
                ? "text-gold-200"
                : menuItemHoverClass,
              expanded && "pl-9",
            )}
            onClick={toggleMenu}
            aria-label={label}
            tabIndex={-1}
          >
            <div className="mr-2">{icon}</div>
            {expanded && label}
          </Button>
        </Link>
      </ToolTipDefault>
    );
  };

  return (
    <>
      <TooltipProvider delayDuration={300}>
        <Tooltip>
          <TooltipTrigger asChild>
            <li className="w-full list-none" aria-label={label}>
              {hasSubItems ? (
                <div
                  className="flex w-full items-center justify-between"
                  onClick={() =>
                    setExpandSubItem((prev: any) => (prev !== href ? href : ""))
                  }
                  aria-label={`${label} sub items`}
                  tabIndex={0}
                >
                  <Item />
                </div>
              ) : (
                <Link href={href} className="block">
                  <Item />
                </Link>
              )}
            </li>
          </TooltipTrigger>
          <TooltipContent
            side="right"
            sideOffset={10}
            align="start"
            className={cn("z-10 px-3 py-1.5", expanded && "hidden")}
          >
            <p>{label}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      {hasSubItems && subItemExpanded && (
        <div className="space-y-2">
          {subItems.map(({ label, href, icon }: any) => (
            <SubItem label={label} icon={icon} href={href} key={href} />
          ))}
        </div>
      )}
    </>
  );
}
