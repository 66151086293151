import { create } from "zustand";

import { fetchOrgInfo } from "@/components/lab-settings/api";

interface Org {
  name: string;
  slug: string;
  faqs: any;
  maximum_reservation: number;
  access_guidelines: any;
  org_type: string;
  description: string;
  day_start_time: number;
  day_end_time: number;
  advance_booking_limit: number;
  no_show_grace_period: number;
  free_cancellation_window: number;
  timezone: string;
  phone_number: string;
  address_1: string;
  state: string;
  city: string;
  zip: string;
  created_at: string;
  updated_at: string;
  user_role: string;
  users: any[];
  service_faqs: any;
  submission_guidelines: any;
  service_custom_fields: any;
  equipment_custom_fields: any;
  default_contract_type: string;
  working_hours: {
    open: boolean;
    start_time: number;
    end_time: number;
  }[];
}

interface AuthStore {
  org: Org | null;
  loading: boolean;
  init: () => void;
}

const useOrgStore = create<AuthStore>((set) => ({
  org: null,
  loading: false,
  init: async () => {
    try {
      set(() => ({ loading: true }));
      const org = await fetchOrgInfo("/orgs/info");
      set(() => ({ org }));
    } catch (error) {
      console.error(error);
    } finally {
      set(() => ({ loading: false }));
    }
  },
}));

export default useOrgStore;
