import { create } from "zustand";

interface SideBarStore {
  latestUpdateDate: Date | null;
  expanded: boolean;
  focusSwitchBtn: boolean;
  init: () => void;
  toggleSidebar: () => void;
  setLatestUpdateNow: () => void;
  setFocusSwitchBtn: () => void;
}

const useSideBarStore = create<SideBarStore>((set, get) => ({
  latestUpdateDate: null,
  expanded: true,
  focusSwitchBtn: false,
  init: () => {
    set({
      expanded: localStorage.getItem("menu_expand")
        ? localStorage.getItem("menu_expand") === "true"
        : true,
      focusSwitchBtn: false,
    });
  },
  toggleSidebar: () => {
    const expanded = !get().expanded;
    localStorage.setItem("menu_expand", expanded ? "true" : "false");
    set(() => ({ expanded }));
  },
  setLatestUpdateNow: () => {
    set(() => ({ latestUpdateDate: new Date() }));
  },
  setFocusSwitchBtn: () => {
    set(() => ({ focusSwitchBtn: true }));
    setTimeout(() => {
      set(() => ({ focusSwitchBtn: false }));
    }, 2380);
  },
}));

export default useSideBarStore;
