import { API_URL, superFetch } from "@/lib/utils";
import { clearStorage } from "@/stores/auth-store";

import { RegisterRequest } from "./interface";

interface LoginData {
  email: string;
  password: string;
}

export async function fetchLogin(data: LoginData) {
  const res = await fetch(`${API_URL}/users/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return res.json();
}

export async function fetchRegisterUser(data: RegisterRequest) {
  const res = await fetch(`${API_URL}/users/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const json = await res.json();
  if (!res.ok) {
    throw new Error(json.message || "An error occurred");
  }
  return true;
}

export const fetchAcceptInvite = async (invite_token: string) => {
  const url = `${API_URL}/users/accept_invite`;
  const body = JSON.stringify({ invite_token });

  return await superFetch(url, { method: "POST", body });
};

export async function sendVerifyEmail(email: string) {
  const res = await fetch(`${API_URL}/users/resend-verify-email`, {
    method: "POST",
    body: JSON.stringify({ email }),
  });
  return res.json();
}

export async function fetchUniversityList(url: string) {
  const res = await fetch(`${API_URL}${url}`);
  const json = await res.json();
  return json.data;
}

export async function sendResetPasswordEmail(email: string) {
  const res = await fetch(`${API_URL}/users/reset-password`, {
    method: "POST",
    body: JSON.stringify({ email }),
  });
  return res;
}

export async function updatePassword(data: any) {
  const res = await fetch(`${API_URL}/users/update-password`, {
    method: "POST",
    body: JSON.stringify(data),
  });
  return res;
}

export async function refreshToken() {
  const refresh_token = localStorage.getItem("refreshToken");
  if (!refresh_token) {
    throw new Error(
      "[No refresh_token] Some error occurred. Please try again.",
    );
  }

  return superFetch(`${API_URL}/users/refresh`, {
    method: "POST",
    body: JSON.stringify({ refresh_token }),
  }) as any;
}

export async function getRefreshToken(triggerRefreshRef: any) {
  try {
    if (triggerRefreshRef.current) {
      return;
    }
    triggerRefreshRef.current = true;
    const res = await refreshToken();
    const user = res?.data;
    if (!user) {
      throw new Error("Invalid refresh data");
    }
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("authToken", user.token);
    window.location.reload();
  } catch (err) {
    clearStorage();
    const path = window.location.pathname;
    window.location.href = "/login?redirect=" + path;
  }
}
