import { API_URL, superFetch } from "@/lib/utils";

export async function fetchOrgBookingDetail(url: string) {
  const response = (await superFetch(`${API_URL}/${url}`)) as any;
  return response.data;
}

export async function fetchOrgBookings(url: string) {
  const res = (await superFetch(`${API_URL}${url}`)) as any;
  return res.data.bookings;
}
