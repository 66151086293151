import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ChevronDown, ChevronUp, Minus, Plus } from "lucide-react";
import * as React from "react";

import { cn } from "@/lib/utils";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn("border-b", className)}
    {...props}
  />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        "flex flex-1 items-center justify-between bg-background py-4 font-medium transition-all hover:bg-gold-50 hover:underline [&[data-state=closed]>.icon-open]:block [&[data-state=open]>.icon-close]:block",
        className,
      )}
      {...props}
    >
      {children}
      <ChevronDown className="icon-open hidden h-6 w-6 shrink-0" />
      <ChevronUp className="icon-close hidden h-6 w-6 shrink-0" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionTriggerPlus = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        "flex flex-1 items-center justify-between py-4 font-medium transition-all [&[data-state=closed]>.icon-open]:block [&[data-state=open]>.icon-close]:block",
        className,
      )}
      {...props}
    >
      {children}
      <Plus className="icon-open hidden h-6 w-6 shrink-0" />
      <Minus className="icon-close hidden h-6 w-6 shrink-0" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTriggerPlus.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      "overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
      className,
    )}
    {...props}
  >
    <div className="pb-4 pt-0">{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

const AccordionContentMenu = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      "overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
      className,
    )}
    {...props}
  >
    <div className="pt-0">{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContentMenu.displayName = AccordionPrimitive.Content.displayName;
type AccordionTriggerProps = React.ComponentProps<typeof AccordionTrigger> & {
  customRender?: React.ReactNode;
};

const AccordionTriggerDefault = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  AccordionTriggerProps
>(({ className, children, customRender, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex flex-col">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        "flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180",
        className,
      )}
      {...props}
    >
      {children}
      <ChevronDown
        className="shrink-0 transition-transform duration-200"
        size={24}
      />
    </AccordionPrimitive.Trigger>
    {customRender}
  </AccordionPrimitive.Header>
));
AccordionTriggerDefault.displayName = AccordionPrimitive.Trigger.displayName;

export {
  Accordion,
  AccordionContent,
  AccordionContentMenu,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerDefault,
  AccordionTriggerPlus,
};
