export function SwitchingRole({ userType }: { userType: string }) {
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="flex flex-col items-center gap-2">
        <div className="relative flex h-[88px] w-[88px] items-center justify-center">
          <SwitchBorder />
          <SwitchRoleIcon />
        </div>
        <div className="p2-bold">
          Switching mode to {userType === "university" ? "Booking" : "Hosting"}
        </div>
      </div>
    </div>
  );
}

const SwitchRoleIcon = () => (
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24.5" r="24" fill="#185F4E" />
    <path
      d="M21.9998 14.5V22.027C22.0001 22.338 21.9278 22.6448 21.7888 22.923L16.7198 33.05C16.6427 33.203 16.6062 33.3732 16.6138 33.5444C16.6214 33.7155 16.6728 33.8818 16.7632 34.0274C16.8535 34.1729 16.9797 34.2928 17.1297 34.3756C17.2797 34.4584 17.4485 34.5012 17.6198 34.5H30.3798C30.5511 34.5012 30.7198 34.4584 30.8698 34.3756C31.0198 34.2928 31.1461 34.1729 31.2364 34.0274C31.3267 33.8818 31.3782 33.7155 31.3858 33.5444C31.3934 33.3732 31.3569 33.203 31.2798 33.05L26.2108 22.923C26.0717 22.6448 25.9995 22.338 25.9998 22.027V14.5"
      stroke="#FFFEFE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5 14.5H27.5"
      stroke="#FFFEFE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 28.5H29"
      stroke="#FFFEFE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SwitchBorder = () => (
  <svg
    width="88"
    height="89"
    viewBox="0 0 88 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="absolute left-0 top-0 animate-spin"
  >
    <circle cx="44" cy="44.5" r="42" stroke="#C9E6DF" strokeWidth="4" />
    <mask id="path-2-inside-1_6465_24907" fill="white">
      <path d="M3.12153 34.2147C2.13205 33.9657 1.52792 32.9603 1.8182 31.9822C3.98818 24.6697 8.02571 18.0358 13.5464 12.7418C19.0672 7.44789 25.8645 3.69202 33.2615 1.8305C34.251 1.58149 35.2302 2.22723 35.4375 3.22628C35.6447 4.22534 35.0014 5.19903 34.0129 5.45185C27.2953 7.16991 21.1235 10.5953 16.1038 15.4087C11.0841 20.2222 7.40303 26.245 5.40486 32.8847C5.11082 33.8617 4.11102 34.4637 3.12153 34.2147Z" />
    </mask>
    <path
      d="M3.12153 34.2147C2.13205 33.9657 1.52792 32.9603 1.8182 31.9822C3.98818 24.6697 8.02571 18.0358 13.5464 12.7418C19.0672 7.44789 25.8645 3.69202 33.2615 1.8305C34.251 1.58149 35.2302 2.22723 35.4375 3.22628C35.6447 4.22534 35.0014 5.19903 34.0129 5.45185C27.2953 7.16991 21.1235 10.5953 16.1038 15.4087C11.0841 20.2222 7.40303 26.245 5.40486 32.8847C5.11082 33.8617 4.11102 34.4637 3.12153 34.2147Z"
      stroke="#1E7661"
      strokeWidth="8"
      mask="url(#path-2-inside-1_6465_24907)"
    />
  </svg>
);
