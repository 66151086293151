import { ArrowUpRightFromSquare, Mail } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export function HelpButton() {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="fixed bottom-8 right-6 flex h-[60px] w-[60px] items-center justify-center rounded-full bg-gold-200 data-[state=open]:bg-gold-300">
        <img src="/icons/question-mark.svg" alt="question" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" alignOffset={6}>
        <DropdownMenuLabel className="p4-medium px-4 py-2">
          How can we help
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem className="p3 gap-2 p-4">
          <ArrowUpRightFromSquare strokeWidth={1} /> Visit Help Center
        </DropdownMenuItem>
        <DropdownMenuItem className="p3 gap-2 p-4">
          <Mail strokeWidth={1} />
          Contact us at{" "}
          <a href="mailto:support@secondlab.com" className="p3-medium">
            support@secondlab.com
          </a>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
