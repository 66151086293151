import { get } from "lodash-es";
import {
  Award,
  BadgeCheck,
  Building2,
  Calendar,
  Dna,
  FileClock,
  FileDown,
  FlaskConical,
  LayoutDashboard,
  Link as LinkIcon,
  LogOut,
  MessageSquare,
  Microscope,
  Repeat,
  Settings,
  User,
  Users,
  Wrench,
} from "lucide-react";
import { Home } from "lucide-react";
import { TestTube2 } from "lucide-react";
import { CircleDollarSign } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import useSWR from "swr";

import { fetchOrgBookings } from "@/components/bookings/api";
import { fetchOrgInfo } from "@/components/lab-settings/api";
import { MenuItem } from "@/components/sidebar/menu-item";
import { MobileMenu } from "@/components/sidebar/mobile-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { cn, encodeQueryData, superFetchData } from "@/lib/utils";
import dayjs from "@/packages/dayjs";
import orgService from "@/src/services/org.service";
import useAuthStore from "@/stores/auth-store";
import useSideBarStore from "@/stores/sidebar-store";

interface SidebarProps {
  className?: string;
  focusSwitch?: boolean;
}

const labMenu = (userRole: string) =>
  [
    {
      label: "Home",
      icon: <Home size={20} />,
      href: "/hosting",
    },
    {
      label: "Dashboard",
      icon: <LayoutDashboard size={20} />,
      href: "/hosting/dashboard",
    },
    {
      label: "Calendar",
      icon: <Calendar size={20} />,
      href: "/hosting/calendar",
    },
    {
      label: "Bookings",
      icon: <FileClock size={20} />,
      href: "/hosting/bookings",
    },
    {
      label: "Equipment",
      icon: <Microscope size={20} />,
      href: "/hosting/equipments",
    },
    {
      label: "Services",
      icon: <Dna size={20} />,
      href: "/hosting/services",
    },
    {
      label: "Scientific Process",
      icon: <FlaskConical size={20} />,
      href: "/hosting/scientific-process",
    },
    {
      label: "Approvals",
      icon: <BadgeCheck size={20} />,
      href: "/hosting/lab/approvals",
    },
    {
      label: "Messages",
      icon: <MessageSquare size={20} />,
      href: "/hosting/chat/list",
    },
    {
      label: "Finances",
      icon: <CircleDollarSign size={20} />,
      href: "/hosting/finances?tab=payments&filter=subscription",
    },
    {
      label: "Settings",
      icon: <Settings size={20} />,
      href: "/hosting/lab-settings",
      subItems: [
        {
          label: "Account",
          href: "/hosting/account",
          icon: <User size={20} />,
        },
        ...(userRole === "owner" || userRole === "admin"
          ? [
              {
                label: "Organization",
                href: "/hosting/organization",
                icon: <Building2 size={20} />,
              },
              {
                label: "Members",
                href: "/hosting/org-members",
                icon: <Users size={20} />,
              },
              {
                label: "Lab Settings",
                href: "/hosting/lab-settings",
                icon: <TestTube2 size={20} />,
              },
              {
                label: "Subscriptions",
                href: "/hosting/subscriptions",
                icon: <Award size={20} />,
              },
            ]
          : []),
        {
          label: "Integrations",
          href: "/hosting/integrations",
          icon: <LinkIcon size={20} />,
        },
      ],
    },
  ] as any[];

const researcherMenu = (userRole: string) =>
  [
    {
      label: "Home",
      icon: <Home size={20} />,
      href: "/",
    },
    {
      label: "Bookings",
      icon: <FileClock size={20} />,
      href: "/bookings",
    },
    {
      label: "Messages",
      icon: <MessageSquare size={20} />,
      href: "/chat/list",
    },
    {
      label: "Settings",
      icon: <Settings size={20} />,
      href: "/user/settings",
      subItems: [
        // {
        //   label: "Approvals",
        //   href: "/user/approvals",
        //   icon: <BadgeCheck size={20} />,
        // },
        {
          label: "Company",
          href: "/account/company/overview",
          icon: <Building2 size={20} />,
        },
        {
          label: "Account",
          href: "/user/settings/account",
          icon: <User size={20} />,
        },
        // ...(userRole === "owner" || userRole === "admin"
        //   ? [
        //       {
        //         label: "Company",
        //         href: "/account/company",
        //         icon: <Building2 size={20} />,
        //       },
        //       {
        //         label: "Members",
        //         href: "/org-members",
        //         icon: <Users size={20} />,
        //       },
        //     ]
        //   : []),
      ],
    },
  ] as any[];

const uniOfficeMenu = () =>
  [
    {
      label: "Approvals",
      href: "/",
      icon: <BadgeCheck size={20} />,
    },
    {
      label: "Members",
      href: "/org-members",
      icon: <Users size={20} />,
    },
    {
      label: "Exports",
      href: "/university/exports",
      icon: <FileDown size={20} />,
    },
  ] as any[];

const operationsMenu = () =>
  [
    {
      label: "Tools",
      href: "/operation/tools",
      icon: <Wrench size={20} />,
    },
  ] as any[];

export function Sidebar({ className }: SidebarProps) {
  const { user, logout, switchRole } = useAuthStore();
  const { data, isLoading } = useSWR("/orgs/info", fetchOrgInfo);
  const orgInfo = data?.org || data;
  const { expanded, toggleSidebar, latestUpdateDate, focusSwitchBtn } =
    useSideBarStore();

  const userType = orgInfo?.org_type;

  const pathname = usePathname();
  const [expandSubItem, setExpandSubItem] = useState(pathname);
  const isUniversity = orgInfo?.org_type === "university";
  const isCompany = orgInfo?.org_type === "company";
  const { data: bookings = [], mutate } = useSWR(
    isUniversity
      ? `/orgs/bookings${encodeQueryData({
          start_time: dayjs().startOf("day").subtract(1, "month").toISOString(),
          end_time: dayjs().startOf("day").add(3, "month").toISOString(),
          status: "pending_approve",
        })}`
      : null,
    fetchOrgBookings,
  );
  const ApprovalKey =
    isUniversity && !isCompany
      ? encodeQueryData({
          start_time: dayjs().startOf("day").subtract(1, "month").toISOString(),
          end_time: dayjs().startOf("day").add(3, "month").toISOString(),
          status: "pending",
        })
      : null;
  const { data: approvalsData = [] } = orgService.useApprovals(ApprovalKey, {
    revalidateOnFocus: true,
    revalidateIfStale: true,
  });

  useEffect(() => {
    if (latestUpdateDate) {
      mutate();
    }
  }, [latestUpdateDate, mutate]);

  const { data: chatSummary } = useSWR("/chat/summary", superFetchData, {
    revalidateOnFocus: true,
    refreshInterval: 15000,
  });

  const totalPendingApprove = bookings?.length;
  const totalPendingApprovals = approvalsData?.count?.pending;

  let menu = [] as any[];
  let typeDescription = "";
  switch (userType) {
    case "university":
      typeDescription = "Lab Director View";
      menu = labMenu(orgInfo?.user_role);
      break;
    case "company":
      typeDescription = "Researcher View";
      menu = researcherMenu(orgInfo?.user_role);
      break;
    case "uni_office":
      typeDescription = "University View";
      menu = uniOfficeMenu();
      break;
    case "operations":
      typeDescription = "Operations View";
      menu = operationsMenu();
      break;
    default:
      typeDescription = "Unknown View";
  }

  const switchTitle =
    userType === "university" ? "Switch to booking" : "Switch to hosting";

  return (
    <>
      <MobileMenu
        menu={menu}
        isUniversity={isUniversity}
        totalPendingApprove={totalPendingApprove}
        chatSummary={chatSummary}
        userType={userType}
        toggleUserType={switchRole}
        expandSubItem={expandSubItem}
        setExpandSubItem={setExpandSubItem}
        totalPendingApprovals={totalPendingApprovals}
      />
      <div
        className={cn(
          "no-scrollbar fixed z-10 hidden h-screen w-1/5 flex-col overflow-hidden overflow-y-auto bg-emerald-black px-4 py-8 text-slate-200 transition-all md:flex",
          expanded ? "w-[300px]" : "w-[76px]",
        )}
      >
        <div
          className={cn(
            "mb-10 flex h-[57px] items-center justify-center border-b border-b-coffee-500 pb-6",
            expanded && "justify-between",
          )}
        >
          <div className={cn("overflow-hidden", !expanded && "hidden")}>
            <div className="whitespace-nowrap font-logo text-base font-extrabold text-coffee-200">
              <Link href={isUniversity ? "/hosting" : "/"}>
                <img src="/nav-logo.svg" alt="logo" />
              </Link>
            </div>
            <div className="mt-2 whitespace-nowrap text-sm capitalize tracking-wide text-coffee-200">
              {typeDescription}
            </div>
          </div>
          <Button
            className="h-8 w-8 rounded bg-[#323937] p-1 hover:opacity-80"
            onClick={toggleSidebar}
            aria-label="Expand/Collapse sidebar"
          >
            <img
              src={expanded ? "/icons/collapse.svg" : "/icons/expand.svg"}
              alt=""
            />
          </Button>
        </div>
        <menu className="mb-6 space-y-2 text-slate-200">
          {menu.map(({ label, icon, href, subItems }) => (
            <MenuItem
              key={label}
              label={label}
              icon={icon}
              href={href}
              subItems={subItems}
              isUniversity={isUniversity}
              totalPendingApprove={totalPendingApprove}
              chatSummary={chatSummary}
              expanded={expanded}
              expandSubItem={expandSubItem}
              totalPendingApprovals={totalPendingApprovals}
              setExpandSubItem={setExpandSubItem}
            />
          ))}
        </menu>
        <div
          className={cn(
            "relative z-20 mt-auto flex items-center gap-3",
            !expanded && "flex-col gap-2",
          )}
        >
          <div
            className={cn(
              "flex flex-grow cursor-pointer justify-between rounded p-2 pl-0",
              !expanded && "h-10 w-10 p-0",
            )}
          >
            <div className="flex items-center">
              <Avatar className="h-10 w-10">
                <AvatarImage src="" alt="Avatar" />
                <AvatarFallback className="h7 bg-menu-foreground text-base text-coffee-50">
                  {get(user, "first_name[0]", "").toUpperCase()}
                </AvatarFallback>
              </Avatar>
              {expanded && (
                <div className="ml-2 space-y-1 text-left">
                  <div className="text-sm font-semibold">
                    {user?.first_name} {user?.last_name}
                  </div>
                  <div className="text-sm capitalize text-[#C8C6C1]">
                    {orgInfo?.name}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <Button
              className={cn(
                "p-2 hover:bg-transparent",
                !expanded && "h-10 w-10 rounded-full bg-menu-opacity",
              )}
              onClick={() => logout(true)}
              title="Log out"
            >
              <LogOut strokeWidth={1} size={20} />
            </Button>
          </div>
        </div>

        {user?.org?.app_switch_mode_enabled &&
          ["company", "university"].includes(user?.user_type) && (
            <div
              className={cn(
                "mt-2",
                focusSwitchBtn ? "animate-gradient" : "animate-gradient-frame",
                !expanded && "rounded-full",
              )}
            >
              <Button
                className={cn(
                  "h-10 w-full gap-2 bg-menu-foreground text-sm",
                  !expanded && "h-10 w-10 rounded-full bg-menu-opacity",
                )}
                onClick={switchRole}
                title={switchTitle}
              >
                <span className={cn("hidden", expanded && "inline")}>
                  {switchTitle}
                </span>
                <Repeat size={20} strokeWidth={1} className="rotate-90" />
              </Button>
            </div>
          )}
      </div>
    </>
  );
}
