import { API_URL, getAuthToken, superFetch } from "@/lib/utils";

export async function fetchUpdateOrg(data: any) {
  const url = `${API_URL}/orgs`;
  const body = JSON.stringify(data);

  return superFetch(url, { method: "PUT", body });
}

export async function fetchAddOrgIntegration(data: any) {
  const url = `${API_URL}/orgs/integrations`;
  const body = JSON.stringify(data);

  return superFetch(url, { method: "POST", body });
}

export async function fetchDeleteOrgIntegration(data: any) {
  const url = `${API_URL}/orgs/integrations`;
  const body = JSON.stringify(data);

  return superFetch(url, { method: "DELETE", body });
}

export const fetchOrgInfo = async (url: string) => {
  const urlString = `${API_URL}${url}`;
  const token = getAuthToken();
  if (!token) return null;
  const json = (await superFetch(urlString)) as any;
  return json?.data?.org;
};

export async function fetchUpdateOrgTrainings(data: any) {
  const url = `${API_URL}/orgs/trainings`;

  return superFetch(url, { method: "PUT", body: JSON.stringify(data) });
}

export async function fetchCreateSubscription(data: any) {
  const url = `${API_URL}/orgs/subscription-plans`;

  return superFetch(url, { method: "POST", body: JSON.stringify(data) });
}

export async function fetchUpdateSubscription(data: any) {
  const url = `${API_URL}/orgs/subscription-plans`;

  return superFetch(url, { method: "PUT", body: JSON.stringify(data) });
}
